import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'


const lazyRetry = function(componentImport) {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
        );
        // try to import the component
        componentImport().then((component) => {
            window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed) { // not been refreshed yet
                window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh
        });
    });
};

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() =>lazyRetry(() => import('views/auth-views/authentication/login'))),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => lazyRetry(() => import('views/auth-views/authentication/register'))),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => lazyRetry(() => import('views/auth-views/authentication/forgot-password'))),
    },
    {
        key: 'reset-password',
        path: `reset-password`,
        component: React.lazy(() => lazyRetry(() => import('views/auth-views/authentication/reset-password'))),
    }
    
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => lazyRetry(() => import('views/app-views/dashboards/default'))),
    },
    {
        key: 'projects',
        path: `${APP_PREFIX_PATH}/projects`,
        component: React.lazy(() => lazyRetry(() => import('views/app-views/projects'))),
    },
    {
        key: 'studio',
        path: `${APP_PREFIX_PATH}/studio`,
        component: React.lazy(() => lazyRetry(() => import('views/app-views/studio'))),
    },
    {
        key: 'templates',
        path: `${APP_PREFIX_PATH}/templates`,
        component: React.lazy(() => lazyRetry(() => import('views/app-views/templates'))),
    },
    {
        key: 'profile',
        path: `${APP_PREFIX_PATH}/profile`,
        component: React.lazy(() => lazyRetry(() => import('views/app-views/profile'))),
    },
    {
        key: 'preview',
        path: `${APP_PREFIX_PATH}/preview`,
        component: React.lazy(() => lazyRetry(() => import('views/app-views/preview'))),
    },
    {
        key: 'scenes',
        path: `${APP_PREFIX_PATH}/scenes`,
        component: React.lazy(() => lazyRetry(() => import('views/app-views/scenes'))),
    },
    {
        key: 'bonuses',
        path: `${APP_PREFIX_PATH}/bonuses`,
        component: React.lazy(() => lazyRetry(() => import('views/app-views/bonuses'))),
    },
    {
        key: 'teams',
        path: `${APP_PREFIX_PATH}/teams`,
        component: React.lazy(() => import('views/app-views/teams')),
    },
    {
        key: 'fomoclips',
        path: `${APP_PREFIX_PATH}/fomoclips`,
        component: React.lazy(() => import('views/app-views/fomoclips')),
    },
    {
        key: 'fomoeditor',
        path: `${APP_PREFIX_PATH}/fomoclips/editor`,
        component: React.lazy(() => import('views/app-views/fomoclips/editor')),
    },
    {
        key: 'overlay',
        path: `${APP_PREFIX_PATH}/fomoclips/overlay-editor`,
        component: React.lazy(() => import('views/app-views/fomoclips/overlay'))
    },
    {
        key: 'merge',
        path: `${APP_PREFIX_PATH}/video-merge`,
        component: React.lazy(() => import('views/app-views/video-merge'))
    },
    {
        key: 'tutorials',
        path: `${APP_PREFIX_PATH}/tutorials`,
        component: React.lazy(() => import('views/app-views/tutorials'))
    },
    {
        key: 'voicer-list',
        path: `${APP_PREFIX_PATH}/voicer-list`,
        component: React.lazy(() => import('views/app-views/gpt-voices/VoicerList'))
    },
    {
        key: 'voicer',
        path: `${APP_PREFIX_PATH}/voicer`,
        component: React.lazy(() => import('views/app-views/gpt-voices'))
    },
    {
        key: 'whitelabelusers',
        path: `${APP_PREFIX_PATH}/agency/users`,
        component: React.lazy(() => import('views/app-views/whitelabel/users'))
    },
    {
        key: 'whitelabelsettings',
        path: `${APP_PREFIX_PATH}/whitelabel`,
        component: React.lazy(() => import('views/app-views/whitelabel/ViewWhiteLabel'))
    },
    {
        key: 'videobookbuilder',
        path: `${APP_PREFIX_PATH}/videobook-builder`,
        component: React.lazy(() => import('views/app-views/VideobookBuilder'))
    },
    {
        key: 'videobookbuilder',
        path: `${APP_PREFIX_PATH}/videobook-slides`,
        component: React.lazy(() => import('views/app-views/VideobookBuilder/scenes'))
    },

]